
body::-webkit-scrollbar {
  width: 20px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: black;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-radius: 100px;
    background-color: #F90141;
    background-clip: content-box;
}


/* http://meyerweb.com/eric/tools/css/reset/ 

   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video 
{
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

html{
    scroll-behavior: smooth;
}


.hide{
  display: none !important;
}

body {
  width: 100%;
  position: relative;
	line-height: 1;
    font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
  background-size: 300% 300%;
  background-image: linear-gradient(
    -45deg,
    #6D053E,
    #150841,
    #690541
  );
  animation: wave 12s ease infinite;
}

#linksPageContainer{
  width: 100%;
  position: relative;
	line-height: 1;
    font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (1600 - 300)));
  background-size: 300% 300%;
  background-image: linear-gradient(
    -45deg,
    #6D053E,
    #150841,
    #690541
  );
  animation: wave 12s ease infinite;

}
@keyframes wave {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}



h1{
    color: white;
}

body{
    font-family: 'Montserrat', sans-serif;
  width: 100%;
  height: 100%;
}

 .navTrans{
   animation: navTransformed 1s ease;
   animation-fill-mode: forwards;

   img{
     width: 1.8em;
   }
  }

 .navTransRev{
   animation: navTransformedReverse 1s ease;
   animation-fill-mode: forwards;
  }

  @keyframes navTransformedReverse {
  to {
    opacity: 1;
    height: 3.5em;
  }
  from {
    background:black;
    opacity:0.7;
    height:2em;
    padding-top: 0.3em;
  }
}
  @keyframes navTransformed {

  from {
    height: 3.5em;
  }
  to {
    background:black;
    height:2em;
    padding-top: 0.3em;
  }
}
html, body {
min-width: 225px;
}