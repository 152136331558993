body::-webkit-scrollbar {
  width: 20px;
}

body::-webkit-scrollbar-track {
  background: #000;
}

body::-webkit-scrollbar-thumb {
  background-color: #f90141;
  background-clip: content-box;
  border: 5px solid #0000;
  border-radius: 100px;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

html {
  scroll-behavior: smooth;
}

.hide {
  display: none !important;
}

body, #linksPageContainer {
  background-image: linear-gradient(-45deg, #6d053e, #150841, #690541);
  background-size: 300% 300%;
  width: 100%;
  font-size: calc(.923077vw + 11.2308px);
  line-height: 1;
  animation: 12s infinite wave;
  position: relative;
}

@keyframes wave {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1 {
  color: #fff;
}

body {
  width: 100%;
  height: 100%;
  font-family: Montserrat, sans-serif;
}

.navTrans {
  animation: 1s forwards navTransformed;
}

.navTrans img {
  width: 1.8em;
}

.navTransRev {
  animation: 1s forwards navTransformedReverse;
}

@keyframes navTransformedReverse {
  to {
    opacity: 1;
    height: 3.5em;
  }

  from {
    opacity: .7;
    background: #000;
    height: 2em;
    padding-top: .3em;
  }
}

@keyframes navTransformed {
  from {
    height: 3.5em;
  }

  to {
    background: #000;
    height: 2em;
    padding-top: .3em;
  }
}

html, body {
  min-width: 225px;
}

/*# sourceMappingURL=index.24013575.css.map */
